<script setup lang="ts">
import OzBasePopover, { OzBasePopoverColors, type Anchor } from '@@/library/v4/components/OzBasePopover.vue'
import OzOverlay from '@@/library/v4/components/OzOverlay.vue'
import type { ComputePositionConfig } from '@floating-ui/vue'

withDefaults(
  defineProps<{
    /* ---------------------- */
    /* FUNCTIONALITY          */
    /* ---------------------- */
    /**
     * An anchor. Either an element or a pair of coordinates (x, y).
     */
    anchor: Anchor
    /**
     * Popover configuration. Uses Floating UI.
     * Reference: https://floating-ui.com/
     */
    config: Partial<ComputePositionConfig>

    /* ---------------------- */
    /* GENERAL APPEARANCE     */
    /* ---------------------- */
    /**
     * Dark mode
     */
    darkMode?: boolean | 'auto'

    /* ---------------------- */
    /* OVERLAY APPEARANCE     */
    /* ---------------------- */
    /**
     * Whether to show a scrim.
     */
    xScrim?: boolean
    /**
     * Whether the popover is modal or not.
     * If enabled, focus will be trapped and
     * content behind the scrim won't be interactive.
     */
    isModal?: boolean
    /**
     * Whether the popover should fade in.
     */
    shouldFadeIn?: boolean

    /* ---------------------- */
    /* BOX APPEARANCE         */
    /* ---------------------- */
    /**
     * The popover body radius. From OzBox.
     */
    radius?: 0 | 8 | 12 | 16 | 20 | 24
    /**
     * The popover body color. From OzBox.
     */
    color?: OzBasePopoverColors
    /**
     * Classes to apply to the popover body.
     */
    boxClasses?: string | string[]
  }>(),
  {
    xScrim: true,
    darkMode: 'auto',
    isModal: true,
    shouldFadeIn: false,
    radius: 20,
    color: OzBasePopoverColors.PrimaryBlur,
    boxClasses: undefined,
  },
)

const emit = defineEmits<{
  (name: 'scrim-click'): void
  (name: 'scrim-esc'): void
}>()

const scrimClick = (): void => emit('scrim-click')
const scrimEsc = (): void => emit('scrim-esc')
</script>

<script lang="ts">
export default {}
export { OzBasePopoverColors as OzPopoverColors } from '@@/library/v4/components/OzBasePopover.vue'
</script>

<template>
  <OzOverlay
    :scrim="xScrim ? 'popover' : null"
    :is-spectral="!isModal"
    :should-fade-in="shouldFadeIn"
    :dark-mode="darkMode"
    @scrim-click="scrimClick"
    @scrim-esc="scrimEsc"
  >
    <OzBasePopover
      :anchor="anchor"
      :config="config"
      :dark-mode="darkMode"
      :radius="radius"
      :color="color"
      :class="boxClasses"
    >
      <slot></slot>
    </OzBasePopover>
  </OzOverlay>
</template>
